<template>
  <Go :to="payload.download[0].link" style="--color-primary: var(--color-light-green);">
    <Icon class="w-4 h-4 inline mr-8" />{{ payload.title }}
  </Go>
</template>

<script>
import Icon from '@/assets/images/pdf-icon.svg';

export default {
  components: { Icon },
  props: { payload: { type: Object, default: Object } },
};
</script>
