<template>
  <Go
    :to="payload.video != null ? payload.video.link : payload.bild.large"
    style="--color-primary: var(--color-light-green);"
  >
    <component :is="icon" class="w-4 h-4 inline mr-8" />{{ payload.title }}
  </Go>
</template>

<script>
import VideoIcon from '@/assets/images/video-icon.svg';
import BildIcon from '@/assets/images/image-icon.svg';

export default {
  components: { VideoIcon, BildIcon },
  props: { payload: { type: Object, default: Object } },
  computed: {
    icon() {
      if (this.payload.video != null) {
        return 'VideoIcon';
      }

      return 'BildIcon';
    },
  },
};
</script>
