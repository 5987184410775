<template>
  <section class="bg-green weitere-informationen py-16">
    <div class="constrain-4/5 text-center">
      <h2 class="h3" v-text="payload.title" />
    </div>
    <div class="constrain-4/5">
      <div v-if="payload.textoben" class="my-4" v-html="payload.textoben" />
      <div>
        <div
          v-for="info in payload.informationen"
          :key="info.data.id"
          class="py-2 info-line"
        >
          <component :is="info.component" :payload="info.data" />
        </div>
      </div>
      <div v-if="payload.textunten" class="mt-8" v-html="payload.textunten" />
    </div>
  </section>
</template>

<script>
import Link from '@/components/parts/WeitereInformationen-Link.vue';
import Download from '@/components/parts/WeitereInformationen-Download.vue';
import Media from '@/components/parts/WeitereInformationen-Media.vue';

export default {
  components: {
    'WeitereInformation-Link': Link,
    'WeitereInformation-Download': Download,
    'WeitereInformation-Media': Media,
  },
  props: { payload: { type: Object, default: Object } },
};
</script>

<style lang="scss" scoped>
.info-line {
  @apply border-b-4 border-black;

  &:last-of-type {
    @apply border-b-0;
  }
}
</style>
